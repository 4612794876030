import React from "react"

const Promos = () => {
  return (
    <div>
      <h1>View all Promos</h1>
    </div>
  )
}
export default Promos
